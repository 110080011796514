import { useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table'
import { Box, IconButton, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import React, { useState, useEffect } from 'react'

interface UseCustomTableProps<TData extends Record<string, any>> {
  columns: MRT_ColumnDef<TData>[];
  data: TData[];
  readOnly?: boolean;
  tableTitle?: string;
  disableDelete?: boolean;
  disableActions?: boolean;
  onDelete?: (row: any) => void;
  onCreate?: (data: any) => void;
  onCreateCancel?: () => void;
  onEditRow?: () => void;
  onEditRowCancel?: () => void;
}

export function useCustomTable<TData extends Record<string, any>>({
  columns,
  data,
  readOnly = false,
  tableTitle = "",
  disableDelete = false,
  disableActions = false,
  onDelete,
  onCreate,
  onCreateCancel,
  onEditRow,
  onEditRowCancel,
}: UseCustomTableProps<TData>) {

  const table = useMaterialReactTable({
    columns,
    data,
    editDisplayMode: 'modal',
    createDisplayMode: 'modal',
    localization: {
      edit: tableTitle ? 'Add New ' + tableTitle : 'Add New Entry',
    },
    enableEditing: !readOnly,
    enableRowActions: !(readOnly || disableActions),
    positionActionsColumn: 'last',
    onCreatingRowCancel: () => onCreateCancel?.(),
    onCreatingRowSave: onCreate,
    onEditingRowSave: onEditRow,
    onEditingRowCancel: () => onEditRowCancel?.(),
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        {!disableDelete &&
          <Tooltip title="Delete" >
            <IconButton onClick={(e) => {
              e.stopPropagation()
              onDelete?.(row)
            }} >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        }
      </Box>
    ),
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        if (!readOnly) {
          table.setEditingRow(row)
        }
      },
      sx: { cursor: !readOnly ? 'pointer' : 'default' }
    }),

    // Disable toolbars and features
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableFullScreenToggle: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableGlobalFilter: false,
    enablePagination: false,
    enableDensityToggle: false,
    enableColumnResizing: false,
    enableHiding: false,
  })

  const [editTitle, setEditTitle] = useState('')

  useEffect(() => {
    setEditTitle(
      table.getState().creatingRow
        ? tableTitle ? `Add New ${tableTitle}` : 'Add New Entry'
        : tableTitle ? `Edit ${tableTitle}` : 'Edit Entry'
    )
  }, [table.getState().creatingRow, tableTitle])

  table.options.localization.edit = editTitle
  return table
}