import React, { useState, useEffect, useMemo, forwardRef, useImperativeHandle } from 'react'
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_TableOptions,
  type MRT_Row,
} from 'material-react-table'
import { ClientData, EmploymentIncome, Employment } from '../../client'
import { getNumberCell } from './CustomCells'
import { foldTable, hideTable, FinanceTableWithMemberIdProps, TableRef } from './financesUtils'
import { useCustomTable } from './useCustomTable'

const EmploymentTable = forwardRef<TableRef, FinanceTableWithMemberIdProps<'Employment & Income', 'Employment'>>(
  ({ eventId, title, type, subType, dataInput, datasetGroup, dynamicValues, hideEmptyTable, foldEmptyTable, readOnly, memberId, onUpdate }, ref) => {

    const [dataList, setDataList] = useState<Employment[]>([])
    const [newData, setNewData] = useState<Employment>()
    const [dsElements, setDsElements] = useState<any>()

    useEffect(() => {
      if (dataInput) {
        setDataList(dataInput)
      }
    }, [dataInput])

    useEffect(() => {
      if (datasetGroup && datasetGroup['elements']) {
        setDsElements(datasetGroup['elements'])
      }
    }, [datasetGroup])

    useImperativeHandle(ref, () => ({
      createRowFunction: () => {
        table.setCreatingRow(true)
      },
    }))

    const handleCreate: MRT_TableOptions<Employment>['onCreatingRowSave'] = async ({
      values,
      table,
    }) => {
      if (newData) {
        newData.member_id = memberId
        onUpdate(type, subType, [newData], memberId)
      }
      table.setCreatingRow(null) // exit creating mode
    }

    const handleEdit = async () => {
      const error = await onUpdate(type, subType, dataList, memberId)
      if (error) {
        handleEditCancel()
        return
      }
      table.setEditingRow(null)
    }

    function handleEditCancel() {
      if (dataInput)
        setDataList(dataInput)
      return
    }

    function handleDelete(row: MRT_Row<Employment>) {
      if (row.index >= 0) {
        onUpdate(type, subType, undefined, memberId)
      }
    }

    const columns = useMemo<MRT_ColumnDef<Employment>[]>(
      () => [
        {
          accessorKey: 'Employer',
          header: 'Employer',
          muiEditTextFieldProps: ({ row }) => ({
            onBlur: (event) => {
              if (row.index < 0) {
                setNewData((prevNew) => ({
                  ...prevNew,
                  'Employer': event.target.value,
                }))
                return
              }
              const rowIdx = Number(row.id)
              const value = (event.target.value)
              setDataList(prevDataList =>
                prevDataList.map((item, index) =>
                  index === rowIdx ? { ...item, ['Employer']: value } : item
                )
              )
            },
          }),
          size: 140
        },
        {
          accessorKey: 'Title / Job Position',
          header: 'Title / Job Position',
          muiEditTextFieldProps: ({ row }) => ({
            onBlur: (event) => {
              if (row.index < 0) {
                setNewData((prevNew) => ({
                  ...prevNew,
                  'Title / Job Position': event.target.value,
                }))
                return
              }
              const rowIdx = Number(row.id)
              const value = (event.target.value)
              setDataList(prevDataList =>
                prevDataList.map((item, index) =>
                  index === rowIdx ? { ...item, ['Title / Job Position']: value } : item
                )
              )
            },
          }),
          size: 100
        },
        {
          accessorKey: 'No. of Years Working Here',
          header: 'No. of Years Working Here',
          muiEditTextFieldProps: ({ row }) => ({
            type: 'number',
            value: row.index < 0 ? (newData?.['No. of Years Working Here'] ?? '') : (dataList[Number(row.id)]?.['No. of Years Working Here'] ?? ''),
            onChange: (event) => {
              const value = event.target.value == "" ? undefined : Number(event.target.value)
              if (row.index < 0) {
                setNewData((prevNew) => ({
                  ...prevNew,
                  'No. of Years Working Here': value,
                }))
                return
              }
              const rowIdx = Number(row.id)
              setDataList(prevDataList =>
                prevDataList.map((item, index) =>
                  index === rowIdx ? { ...item, ['No. of Years Working Here']: value } : item
                )
              )
            },
          }),
          Cell: getNumberCell({ style: 'decimal' }),
          size: 100
        },
        {
          accessorKey: 'Salary & Tips',
          header: 'Salary & Tips',
          muiEditTextFieldProps: ({ row }) => ({
            type: 'number',
            onBlur: (event) => {
              if (row.index < 0) {
                setNewData((prevNew) => ({
                  ...prevNew,
                  'Salary & Tips': Number(event.target.value),
                }))
                return
              }
              const rowIdx = Number(row.id)
              const value = Number(event.target.value)
              setDataList(prevDataList =>
                prevDataList.map((item, index) =>
                  index === rowIdx ? { ...item, ['Salary & Tips']: value } : item
                )
              )
            },
          }),
          Cell: getNumberCell(),
          size: 100
        },
        {
          accessorKey: 'Bonus & Commissions',
          header: 'Bonus & Commissions',
          muiEditTextFieldProps: ({ row }) => ({
            type: 'number',
            onBlur: (event) => {
              if (row.index < 0) {
                setNewData((prevNew) => ({
                  ...prevNew,
                  'Bonus & Commissions': Number(event.target.value),
                }))
                return
              }
              const rowIdx = Number(row.id)
              const value = Number(event.target.value)
              setDataList(prevDataList =>
                prevDataList.map((item, index) =>
                  index === rowIdx ? { ...item, ['Bonus & Commissions']: value } : item
                )
              )
            },
          }),
          Cell: getNumberCell(),
          size: 100
        },
      ],
      [dataList, newData, dsElements, dynamicValues],
    )

    const table = useCustomTable({
      columns,
      data: dataList,
      tableTitle: title,
      readOnly: readOnly,
      onCreate: handleCreate,
      onDelete: handleDelete,
      onEditRow: handleEdit,
      onEditRowCancel: handleEditCancel,
    })

    return (
      <MaterialReactTable table={table} />
    )
  }
)

EmploymentTable.displayName = 'EmploymentTable'

export default EmploymentTable