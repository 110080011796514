import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  Box, IconButton, Tooltip,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import {
  useMaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
} from 'material-react-table'
import { Annuity } from '../../client'
import DeleteIcon from '@mui/icons-material/Delete'
import { foldTable, hideTable, FinanceTableProps, getEditOptions } from './financesUtils'
import TableAccordion from './TableAccordion'
import { getNumberCell, getLabelCell } from './CustomCells'
import { useCustomTable } from './useCustomTable'

export default function AnnuitiesTable({ eventId, title, type, subType, dataInput, datasetGroup, dynamicValues, hideEmptyTable, foldEmptyTable, readOnly, onUpdate }: FinanceTableProps<'Assets', 'Annuities'>) {

  const [expanded, setExpanded] = useState(true)
  const [dataList, setDataList] = useState<Annuity[]>([])
  const [newData, setNewData] = useState<Annuity>()
  const [dsElements, setDsElements] = useState<any>()

  useEffect(() => {
    if (dataInput) {
      setDataList(dataInput)
    }
  }, [dataInput])

  useEffect(() => {
    if (datasetGroup && datasetGroup['elements']) {
      setDsElements(datasetGroup['elements'])
    }
  }, [datasetGroup])

  useEffect(() => {
    setExpanded(foldTable(foldEmptyTable, dataList))
  }, [foldEmptyTable, dataList])

  const handleCreate: MRT_TableOptions<Annuity>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    if (newData) {
      const updated = [...dataList]
      updated.push(newData)
      const error = await onUpdate(type, subType, updated)
      if (error) {
        return
      }
    }
    table.setCreatingRow(null) // exit creating mode
  }

  const handleEdit = async () => {
    const error = await onUpdate(type, subType, dataList)
    if (error) {
      if (dataInput)
        setDataList(dataInput)
      return
    }
    table.setEditingRow(null)
  }

  function handleEditCancel() {
    if (dataInput)
      setDataList(dataInput)
    return
  }

  function handleDelete(row: MRT_Row<Annuity>) {
    const updated = [...dataList]
    if (row.index >= 0) {
      updated.splice(row.index, 1)
      onUpdate(type, subType, updated)
    }
  }

  const columns = useMemo<MRT_ColumnDef<Annuity>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevNew) => ({
                ...prevNew,
                'Name': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            setDataList(prevDataList =>
              prevDataList.map((item, index) =>
                index === rowIdx ? { ...item, Name: event.target.value } : item
              )
            )
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Owner',
        header: 'Owner',
        editSelectOptions: () => getEditOptions(dsElements, 'Owner', dynamicValues),
        Cell: getLabelCell(() => getEditOptions(dsElements, 'Owner', dynamicValues)),
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevNew) => ({
                ...prevNew,
                'Owner': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const value = event.target.value
            setDataList(prevDataList =>
              prevDataList.map((item, index) =>
                index === rowIdx ? { ...item, Owner: value } : item
              )
            )
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Type',
        header: 'Type',
        editSelectOptions: getEditOptions(dsElements, 'Type', dynamicValues),
        Cell: getLabelCell(() => getEditOptions(dsElements, 'Type', dynamicValues)),
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevNew) => ({
                ...prevNew,
                'Type': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const value = event.target.value
            setDataList(prevDataList =>
              prevDataList.map((item, index) =>
                index === rowIdx ? { ...item, Type: value } : item
              )
            )
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Contributions',
        header: 'Contributions',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevNewFund) => ({
                ...prevNewFund,
                'Contributions': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.index)
            const value = Number(event.target.value)
            setDataList(prevDataList =>
              prevDataList.map((item, index) =>
                index === rowIdx ? { ...item, Contributions: value } : item
              )
            )
          },
        }),
        Cell: getNumberCell(),
        size: 100
      },
      {
        accessorKey: 'Account Value',
        header: 'Account Value',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevNew) => ({
                ...prevNew,
                'Account Value': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.index)
            const value = Number(event.target.value)
            setDataList(prevDataList =>
              prevDataList.map((item, index) =>
                index === rowIdx ? { ...item, ['Account Value']: value } : item
              )
            )
          },
        }),
        Cell: getNumberCell(),
        size: 100
      },
      {
        accessorKey: 'Distribution Type',
        header: 'Distribution Type',
        editSelectOptions: getEditOptions(dsElements, 'Distribution Type', dynamicValues),
        Cell: getLabelCell(() => getEditOptions(dsElements, 'Distribution Type', dynamicValues)),
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Distribution Type': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const value = event.target.value
            setDataList(prevDataList =>
              prevDataList.map((item, index) =>
                index === rowIdx ? { ...item, ['Distribution Type']: value } : item
              )
            )
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Distribution Amount',
        header: 'Distribution Amount',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Distribution Amount': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.id)
            const value = Number(event.target.value)
            setDataList(prevDataList =>
              prevDataList.map((item, index) =>
                index === rowIdx ? { ...item, ['Distribution Amount']: value } : item
              )
            )
          },
        }),
        Cell: getNumberCell(),
        size: 100
      },
      {
        accessorKey: 'Distribution Year',
        header: 'Distribution Year',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Distribution Year': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.id)
            const value = Number(event.target.value)
            setDataList(prevDataList =>
              prevDataList.map((item, index) =>
                index === rowIdx ? { ...item, ['Distribution Year']: value } : item
              )
            )
          },
        }),
        size: 100
      },
    ],
    [dataList, newData, dsElements, dynamicValues],
  )

  const table = useCustomTable({
    columns,
    data: dataList,
    readOnly: readOnly,
    tableTitle: title,
    onDelete: handleDelete,
    onCreate: handleCreate,
    onEditRow: handleEdit,
    onEditRowCancel: handleEditCancel,
  })

  if (hideTable(hideEmptyTable, dataList)) {
    return (
      <></>
    )
  }

  return (
    <TableAccordion title={title} table={table} readOnly={readOnly} expanded={expanded} setExpanded={setExpanded} />
  )
}
