import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  SxProps, Box, CardHeader, IconButton, Tooltip, Typography,
} from '@mui/material'
import { ClientData } from '../../client'
import PersonalDetailsTable from './PersonalDetailsTable'
import EmploymentTable from './EmploymentTable'
import SubCard from 'components/SubCard'
import { UpdateClientDataInterface, TableRef } from './financesUtils'
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'

interface Props {
  eventId?: string
  clientData?: ClientData
  datasetByTitle?: any
  dynamicValues?: any
  readOnly: boolean
  onUpdate: UpdateClientDataInterface
  onDeleteClient: (memberId: string) => void
}

export default function ClientList({ eventId, clientData, datasetByTitle, dynamicValues, readOnly, onUpdate, onDeleteClient }: Props) {

  const [clientMap, setClientMap] = useState<any>({})
  const [memberIdList, setMemberIdList] = useState<any[]>([])
  const clientTableRef = useRef<TableRef>(null)
  const employmentTableRefList = useRef<(TableRef | null)[]>([])

  useEffect(() => {
    const idList: any[] = []
    const updatedMap: any = {}
    if (clientData?.['Client Details']?.['Personal Details']) {
      const pds = clientData?.['Client Details']?.['Personal Details']
      for (const pd of pds) {
        if (pd.member_id) {
          idList.push(pd.member_id)
          updatedMap[pd.member_id] = {
            'Personal Details': [pd],
            'Employment': []
          }
        }
      }
    }
    if (clientData?.['Employment & Income']?.['Employment']) {
      const emps = clientData?.['Employment & Income']?.['Employment']
      for (const em of emps) {
        if (em.member_id && idList.includes(em.member_id) && em.member_id in updatedMap) {
          updatedMap[em.member_id]['Employment'] = [em]
        }
      }
    }
    setMemberIdList(idList)
    setClientMap(updatedMap)
  }, [JSON.stringify(clientData?.['Client Details']?.['Personal Details']), JSON.stringify(clientData?.['Employment & Income']?.['Employment'])])

  async function onUpdateOneMember<
    P extends keyof ClientData,
    S extends keyof NonNullable<ClientData[P]>,
  >(
    type: P,
    subType: S,
    // updated: NonNullable<ClientData[P]>[S],
    updated: any,
    memberId: string
  ): Promise<Error | undefined> {

    const updatedClientData = { ...clientData }
    if (!updatedClientData[type]) {
      updatedClientData[type] = {} as NonNullable<ClientData[P]>
    }

    if (!(updatedClientData[type] as NonNullable<ClientData[P]>)[subType]) {
      (updatedClientData[type] as NonNullable<ClientData[P]>)[subType] = [] as NonNullable<ClientData[P]>[S]
    }

    let currentArray = (updatedClientData[type] as NonNullable<ClientData[P]>)[subType] as Array<{ member_id: string }>
    if (updated) {
      // update or add
      const memberIndex = currentArray.findIndex(item => item.member_id === memberId)
      if (memberIndex === -1 && Array.isArray(updated)) {
        const castedUpdated = updated[0]
        castedUpdated.member_id = memberId
        currentArray.push(castedUpdated)
      } else {
        currentArray[memberIndex] = {
          ...currentArray[memberIndex],
          ...updated[0]
        }
      }
    } else {
      // when updated == undefined: delete
      currentArray = currentArray.filter(item => item.member_id !== memberId)
    }
    const error = await onUpdate<P, S>(type, subType, currentArray)
    return error
  }

  function onCreateCoClient() {
    if (clientTableRef) {
      clientTableRef.current?.createRowFunction()
    }
  }

  function onCreateEmployment(idx: number) {
    if (employmentTableRefList && employmentTableRefList.current && employmentTableRefList.current[idx]) {
      employmentTableRefList.current[idx]?.createRowFunction()
    }
  }

  return (
    <>
      <Box sx={{ ...stickyHeading, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <Typography variant="h5">Client & Co-Client</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Client&apos;s personal and employment details
          </Typography>
        </div>
        {memberIdList.length <= 1 &&
          <Tooltip title="Add Co-Client">
            <IconButton onClick={(e) => {
              e.stopPropagation()
              onCreateCoClient()
            }}>
              <PersonAddIcon />
            </IconButton>
          </Tooltip>
        }
      </Box>
      {memberIdList.map((memberId, idx) => (
        <SubCard key={memberId}>
          <CardHeader
            title={`${clientMap[memberId]?.['Personal Details'][0]['First Name'] ?? ""} ${clientMap[memberId]?.['Personal Details'][0]['Last Name'] ?? ""}`.trim()}
            sx={{ paddingTop: 1 }}
            action={
              <>
                {idx == 1 &&
                  <Tooltip title="Delete Co-Client">
                    <IconButton onClick={(e) => {
                      e.stopPropagation()
                      onDeleteClient(memberId)
                    }}>
                      <PersonRemoveIcon />
                    </IconButton>
                  </Tooltip>}
                {
                  (!clientMap[memberId]?.['Employment'] || clientMap[memberId]?.['Employment'].length == 0) &&
                  <Tooltip title="Add Employment">
                    <IconButton onClick={(e) => {
                      e.stopPropagation()
                      onCreateEmployment(idx)
                    }}>
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Tooltip>
                }

              </>
            }
          />

          <PersonalDetailsTable title="Client" type={"Client Details"} subType={"Personal Details"} eventId={eventId} dataInput={clientMap[memberId]?.['Personal Details']} onUpdate={onUpdateOneMember<"Client Details", 'Personal Details'>} datasetGroup={datasetByTitle?.['Client Details']?.['groups']?.['Personal Details']} dynamicValues={dynamicValues} memberId={memberId} readOnly={readOnly} hideEmptyTable={false} foldEmptyTable={false} ref={idx == 0 ? clientTableRef : null} />

          < Box sx={{ padding: '1vh' }} />

          <EmploymentTable title="Employment" type={"Employment & Income"} subType={"Employment"} eventId={eventId} dataInput={clientMap[memberId]?.['Employment']} onUpdate={onUpdateOneMember<"Employment & Income", 'Employment'>} datasetGroup={datasetByTitle?.['Employment & Income']?.['groups']?.['Employment']} dynamicValues={dynamicValues} memberId={memberId} readOnly={readOnly} hideEmptyTable={false} foldEmptyTable={false} ref={(el) => employmentTableRefList.current[idx] = el} />

        </SubCard>
      ))}
    </>
  )
}

// TODO: share
const stickyHeading: SxProps = {
  position: 'sticky',
  top: -25,
  backgroundColor: 'white',
  zIndex: 1,
  paddingTop: '20px',
  paddingBottom: "5px",
  paddingLeft: "0px",
  width: '100%',
  overflow: 'hidden',
}