import React, { useEffect, useMemo, useRef, useState } from 'react'

import { type MRT_ColumnDef, type MRT_Row, type MRT_TableOptions } from 'material-react-table'
import TableAccordion from './TableAccordion'
import { CollegeGoal } from '../../client'
import { getNumberCell, getLabelCell } from './CustomCells'
import { foldTable, hideTable, FinanceTableProps, getEditOptions } from './financesUtils'
import { useCustomTable } from './useCustomTable'

export default function CollegeGoalTable({ eventId, title, type, subType, dataInput, datasetGroup, dynamicValues, hideEmptyTable, foldEmptyTable, readOnly, onUpdate }: FinanceTableProps<'Goals', 'College'>) {

  const [expanded, setExpanded] = useState(true)
  const [dataList, setDataList] = useState<CollegeGoal[]>([])
  const [newData, setNewData] = useState<CollegeGoal>()
  const [dsElements, setDsElements] = useState<any>()

  useEffect(() => {
    if (dataInput) {
      setDataList(dataInput)
    }
  }, [dataInput])

  useEffect(() => {
    if (datasetGroup && datasetGroup['elements']) {
      setDsElements(datasetGroup['elements'])
    }
  }, [datasetGroup])

  useEffect(() => {
    setExpanded(foldTable(foldEmptyTable, dataList))
  }, [foldEmptyTable, dataList])

  const handleCreate: MRT_TableOptions<CollegeGoal>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const updated = [...dataList]
    if (newData) {
      updated.push(newData)
      const error = await onUpdate(type, subType, updated)
      if (error) {
        return
      }
    }
    table.setCreatingRow(null) // exit creating mode
  }

  const handleEdit = async () => {
    const error = await onUpdate(type, subType, dataList)
    if (error) {
      if (dataInput)
        setDataList(dataInput)
      return
    }
    table.setEditingRow(null)
  }

  function handleEditCancel() {
    if (dataInput)
      setDataList(dataInput)
    return
  }

  function handleDelete(row: MRT_Row<CollegeGoal>) {
    const updated = [...dataList]
    if (row.index >= 0) {
      updated.splice(row.index, 1)
      onUpdate(type, subType, updated)
    }
  }

  const columns = useMemo<MRT_ColumnDef<CollegeGoal>[]>(
    () => [
      {
        accessorKey: 'For',
        header: 'For',
        editSelectOptions: () => getEditOptions(dsElements, 'For', dynamicValues),
        Cell: getLabelCell(() => getEditOptions(dsElements, 'For', dynamicValues)),
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'For': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const value = (event.target.value)
            setDataList(prevDataList =>
              prevDataList.map((item, index) =>
                index === rowIdx ? { ...item, ['For']: value } : item
              )
            )
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Priority',
        header: 'Priority',
        editSelectOptions: getEditOptions(dsElements, 'Priority', dynamicValues),
        Cell: getLabelCell(() => getEditOptions(dsElements, 'Priority', dynamicValues)),
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Priority': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const value = (event.target.value)
            setDataList(prevDataList =>
              prevDataList.map((item, index) =>
                index === rowIdx ? { ...item, ['Priority']: value } : item
              )
            )
          },
        }),
        size: 25
      },
      {
        accessorKey: 'Start Year',
        header: 'Start Year',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Start Year': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.id)
            const value = Number(event.target.value)
            setDataList(prevDataList =>
              prevDataList.map((item, index) =>
                index === rowIdx ? { ...item, ['Start Year']: value } : item
              )
            )
          },
        }),
        size: 25
      },
      {
        accessorKey: 'End Year',
        header: 'End Year',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'End Year': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.id)
            const value = Number(event.target.value)
            setDataList(prevDataList =>
              prevDataList.map((item, index) =>
                index === rowIdx ? { ...item, ['End Year']: value } : item
              )
            )
          },
        }),
        size: 25
      },
      {
        accessorKey: 'Annual Amount Needed',
        header: 'Annual Amount Needed',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Annual Amount Needed': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.id)
            const value = Number(event.target.value)
            setDataList(prevDataList =>
              prevDataList.map((item, index) =>
                index === rowIdx ? { ...item, ['Annual Amount Needed']: value } : item
              )
            )
          },
        }),
        Cell: getNumberCell(),
        size: 25
      },
    ],
    [dataList, newData, dsElements, dynamicValues],
  )

  const table = useCustomTable({
    columns,
    data: dataList,
    readOnly: readOnly,
    tableTitle: title,
    onDelete: handleDelete,
    onCreate: handleCreate,
    onEditRow: handleEdit,
    onEditRowCancel: handleEditCancel,
    // onCancel: handleCancel,
  })
  if (hideTable(hideEmptyTable, dataList)) {
    return (
      <></>
    )
  }

  return (
    <TableAccordion title={title} table={table} readOnly={readOnly} expanded={expanded} setExpanded={setExpanded} />
  )
}
