import React, { useEffect, useMemo, useRef, useState } from 'react'

import { logErr, logInfo } from 'log'
import { getErrorMessage } from "utils"

import { Api } from 'service/api'
import { LoadingButton } from '@mui/lab'
import { getOrg } from 'service/orgsApi'
import CheckIcon from '@mui/icons-material/Check'
import DownloadIcon from '@mui/icons-material/Download'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FinancesTables from 'components/finances/FinancesTables'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import SpeakerNameConfirmDialog from 'components/event/SpeakerNameConfirmDialog'
import { EventFinancesProcessingSkeletonPage, EventFinancesSkeletonPage, EventSkeletonTranscriptInProgressPageForFinances } from 'components/Skeletons'
import { Alert, Box, Button, Card, CardContent, CardHeader, CardMedia, Dialog, FormControlLabel, FormGroup, IconButton, Skeleton, Snackbar, Switch, SxProps, Typography, CardActions } from '@mui/material'

import { Media } from '../../service/media'
import { ClientData, EventBase, ProcessingStatus } from '../../client'
import PrecisefpDialog from '../integrations/precisefp/PrecisefpDialog'
import { retryDelayExpBackOffUptoSevenSecs, retryStopOnlyExpired } from '../../hooks/hookHelpers'
import { getEventSummaryStatus, getEventTranscript, getUnchangedSpeakers } from '../../service/eventApi'
import InsightsIcon from '@mui/icons-material/Insights'
import { updateDynamicValues } from 'components/finances/financesUtils'
import { getPreciseFpDataset, getPreciseFpClientData } from 'service/integrations/precisefpApi'
import { ContactChip } from 'components/client/ContactChip'

interface Props {
  eventId: string
  event?: EventBase
  disabled: boolean
}

export default function EventFinances({ eventId, event, disabled }: Props) {
  const queryClient = useQueryClient()
  const heightTitle = 170
  const contact = event?.contacts?.[0]
  const [cardHeight, setCardHeight] = useState(window.innerHeight - heightTitle)

  const [busy, setBusy] = useState(false)
  const [busyKyc, setBusyKyc] = useState(false)
  const [isFullScreen, setFullScreen] = useState(false)

  const [dynamicValues, setDynamicValues] = useState<any>() // format: { dataset_id: [ {label: owner_name_string, value: member_id }] }

  const [openDialog, setOpenDialog] = useState(false)
  const [openErrorSnack, setOpenErrorSnack] = useState<string | undefined>()
  const [openSuccessSnack, setOpenSuccessSnack] = useState<string | undefined>()
  const [unchangedSpeakers, setUnchangedSpeakers] = useState("")
  const [buildConfirmDialog, setBuildConfirmDialog] = useState(false)
  const [foldEmptyTables, setFoldEmptyTables] = useState(false)

  const { data: clientData, isLoading: isFinancesLoading } = useQuery({
    queryKey: ['getClientData', eventId],
    queryFn: runQuery,
    retry: retryStopOnlyExpired,
    retryDelay: retryDelayExpBackOffUptoSevenSecs,
  })

  async function runQuery() {
    if (eventId) {
      const res = await getPreciseFpClientData(eventId)
      return res
    }
  }

  const { data: dataset, isLoading: isDatasetLoading } = useQuery({
    queryKey: ['getDataset'],
    queryFn: queryGetDataset,
    retry: retryStopOnlyExpired,
    retryDelay: retryDelayExpBackOffUptoSevenSecs,
    refetchOnWindowFocus: queryClient.getQueryData(['getDataset']) === undefined,
  })

  async function queryGetDataset() {
    const res = await getPreciseFpDataset()
    return res
  }

  useEffect(() => {
    if (clientData && dataset) {
      if (dataset["dynamic_ids"]) {
        const dynamicValues = updateDynamicValues(clientData, dataset["dynamic_ids"])
        setDynamicValues(dynamicValues)
      }
    }
  }, [clientData, dataset])

  const { data: eventStatus, isLoading: isEventStatusLoading } = useQuery({
    queryKey: ['getEventSummaryStatus', eventId],
    queryFn: runQueryEventStatus,
    retry: retryStopOnlyExpired,
    retryDelay: retryDelayExpBackOffUptoSevenSecs,
    refetchInterval: () => isFinancesLoading ? 5000 : false, // refetch every 7 sec
  })

  async function runQueryEventStatus() {
    if (eventId) {
      const res = await getEventSummaryStatus(eventId)
      return res
    }
  }

  const { data: transcript } = useQuery({
    queryKey: ['getEventTranscript', eventId],
    queryFn: async () => await getEventTranscript(eventId),
    enabled: queryClient.getQueryData(['getEventTranscript', eventId]) === undefined,
  })

  const { data: org } = useQuery({
    queryKey: ['getOrg'], queryFn: getOrg,
    enabled: queryClient.getQueryData(['getOrg']) === undefined,
  })

  function openSyncDialog() {
    setOpenDialog(true)
  }

  function closeSyncDialog() {
    setOpenDialog(false)
  }

  function isReady() {
    if (isFinancesLoading && !isEventStatusLoading && !eventStatus?.finances_status) {
      return true
    }
    return false
  }

  function isFinancesProcessing() {
    if (isFinancesLoading &&
      (eventStatus?.finances_status == ProcessingStatus.PROCESSING || eventStatus?.finances_status == ProcessingStatus.PROCESSING_QUEUED)
    ) {
      return true
    }
    return false
  }

  function isTranscriptEmpty() {
    if (eventStatus?.transcript_status == ProcessingStatus.DONE_EMPTY_CONTENT)
      return true
    return false
  }

  function isTranscriptProcessing() {
    if (eventStatus?.transcript_status == ProcessingStatus.PROCESSING)
      return true
    return false
  }

  function isTranscriptAborted() {
    if (eventStatus?.transcript_status == ProcessingStatus.ABORTED)
      return true
    return false
  }

  async function downloadKyc() {
    setBusyKyc(true)
    if (eventId) {
      const api = await Api()
      const pdfUrl = await api.fidelityca.buildKycPdf(eventId)

      const link = document.createElement('a')
      link.href = pdfUrl // Update the path if necessary
      link.target = "_blank"
      link.rel = "noopener noreferrer"
      const filename = new URL(pdfUrl).pathname.split('/').pop()
      if (filename)
        link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
    setBusyKyc(false)
  }

  async function buildFinancesCheck() {
    setBusy(true)
    const unchangedSpeakersList = getUnchangedSpeakers(transcript)
    if (unchangedSpeakersList.length == 0) {
      // all the speakers are updated
      await buildFinances()
    } else {
      // show dialog
      const unchangedSpeakersString = unchangedSpeakersList.join(",  ")
      setUnchangedSpeakers(unchangedSpeakersString)
      setBuildConfirmDialog(true)
    }
    setBusy(false)
  }

  const handleCloseConfirmDialog = async (confirm: boolean) => {
    setBusy(true)
    if (confirm) {
      await buildFinances()
    }
    setBuildConfirmDialog(false)
    setBusy(false)
  }

  async function buildFinances() {
    if (eventId) {
      try {
        const api = await Api()
        const res = await api.precisefp.processFinances(eventId)
        await queryClient.invalidateQueries(['getEventSummaryStatus', eventId])
      } catch (e) {
        const err = getErrorMessage(e)
        logErr("buildFinances failed", { err })
        setOpenErrorSnack(err.message)
      }
    }
  }

  async function updatePfClientData(eventId: string, updatedClientData: ClientData) {
    setBusy(true)
    try {
      const api = await Api()
      const res = await api.precisefp.updateClientData(eventId, updatedClientData)
      queryClient.setQueryData(['getClientData', eventId], res)
      setOpenSuccessSnack("Updated")
    } catch (e) {
      const err = getErrorMessage(e)
      logErr("updatePfClientData failed", { err })
      setOpenErrorSnack(err.message)
      await queryClient.invalidateQueries(['getClientData', eventId])
      return e instanceof Error ? e : new Error(String(e))
    }
    setBusy(false)
  }

  async function updateGroupItems(eventId: string, type: string, subType: string, groupItems: any) {
    try {
      const updatedGroupItems = groupItems.map((a: any) => ({ ...a }))
      const api = await Api()
      const res = await api.precisefp.updateClientDataGroup(eventId, type, subType, updatedGroupItems)
      queryClient.setQueryData(['getClientData', eventId], res)
      setOpenSuccessSnack("Updated")
    } catch (e) {
      const err = getErrorMessage(e)
      logErr("updateGroupItems failed", { err })
      setOpenErrorSnack(err.message)
      await queryClient.invalidateQueries(['getClientData', eventId])
      return e instanceof Error ? e : new Error(String(e))
    }
    setBusy(false)
    return undefined
  }

  const sxCard: SxProps = {
    height: cardHeight,
    minHeight: 520,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    overflowX: 'hidden',
    transition: "all 0.3s ease-in-out",
  }

  const sxCardFull: SxProps = {
    position: "fixed",
    overflowY: 'scroll',
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    zIndex: 1300,
    overflow: "auto",
    transition: "all 0.3s ease-in-out",

  }

  if (isTranscriptEmpty()) {
    return (
      <Card sx={sxCard}>
        <Box sx={{ padding: '12vh' }} />
        <Typography sx={{ fontWeight: 'bold' }} textAlign={'center'}>No Meeting Transcript Available</Typography>
        <Typography textAlign={'center'}>Client&apos;s Finances is not available due to an empty transcript.</Typography>
        <Typography textAlign={'center'}>If this seems incorrect, please contact support for assistance.</Typography>
        <Box sx={{ padding: '22vh' }} />
      </Card>)
  }

  if (isTranscriptProcessing()) {
    return <EventSkeletonTranscriptInProgressPageForFinances />
  }

  if (isTranscriptAborted()) {
    return (
      <Card sx={sxCard}>
        <Box sx={{ padding: '12vh' }} />
        <Typography sx={{ fontWeight: 'bold' }} textAlign={'center'}>Transcript Processing Error Occurred</Typography>
        <Typography textAlign={'center'}>We apologize, but an error has occurred during transcript processing.</Typography>
        <Typography textAlign={'center'}>Please contact our support team for further assistance in resolving this issue.</Typography>
        <Box sx={{ padding: '22vh' }} />
      </Card>)
  }

  if (isFinancesProcessing()) {
    return (
      <Card sx={sxCard}>
        <CardHeader
          title="Client's Finances"
          subheader="Generates a detailed financial data based on client meeting, ready for integration with PreciseFP."
          sx={{ paddingTop: 1 }}
        />
        <EventFinancesProcessingSkeletonPage />
      </Card>
    )
  }

  if (isReady()) {
    return (
      <Card sx={sxCard}>
        <Card
          sx={{
            maxWidth: 550, // Adjust width for a smaller card
            margin: '0 auto', // Center the card horizontally
            boxShadow: 3, // Add a subtle shadow for depth
          }}
        >
          <CardHeader
            title="Build Client's Financial Data"
          />
          <CardMedia component="img"
            height="194"
            image={Media.sampleFinancials}
          ></CardMedia>
          <CardContent>
            <Typography>
              Generates a detailed financial data based on client meeting, ready for integration with PreciseFP.
            </Typography>
          </CardContent>

          <CardActions sx={{ justifyContent: "flex-end", padding: 2 }}>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={buildFinancesCheck}
              loading={busy}
              disabled={disabled}
              startIcon={<InsightsIcon />}
            >
              Build
            </LoadingButton>
          </CardActions>
        </Card>
        <SpeakerNameConfirmDialog
          openDialog={buildConfirmDialog}
          closeDialog={handleCloseConfirmDialog}
          busy={busy}
          unchangedSpeakers={unchangedSpeakers}
        />

      </Card>
    )
  }

  if (isFinancesLoading || isEventStatusLoading || isDatasetLoading || !dynamicValues) {
    return (
      <Card sx={sxCard}>
        <CardHeader
          title="Client's Finances"
          subheader="Generates a detailed financial data based on client meeting, ready for integration with PreciseFP."
          sx={{ paddingTop: 1 }}
        />
        <EventFinancesSkeletonPage />
      </Card>
    )
  }

  return (
    <Card sx={isFullScreen ? sxCardFull : sxCard}>
      <CardHeader
        title={
          <>
            <Typography variant='h3' sx={{ marginBottom: 1 }}>{`Client's Finances`}</Typography>
            <ContactChip eventId={eventId} contact={contact} />
          </>
        }
        sx={{ paddingTop: 1 }}
        action={
          <Box sx={sxRow}>
            <FormGroup>
              <FormControlLabel control={<Switch checked={foldEmptyTables} onChange={() => setFoldEmptyTables(!foldEmptyTables)} />} label="Fold Empty Tables" />
            </FormGroup>
            {org?.enable_fidelity_ca &&
              <LoadingButton loading={busyKyc} variant="outlined" startIcon={<DownloadIcon />} onClick={downloadKyc}>KYC Pdf</LoadingButton>}

            <Button
              variant="outlined"
              onClick={openSyncDialog}
              startIcon={<Box component="img" sx={icon} src={Media.precisefpIcon} />}
              endIcon={event?.precisefp_sent && <CheckIcon color='success' fontSize='small' />}
            >
              Precise FP
            </Button>
            <IconButton aria-label="full screen" onClick={() => setFullScreen(!isFullScreen)}>
              {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Box>

        }
      />

      <CardContent sx={{ padding: 0 }}>

        <FinancesTables eventId={eventId} clientDataInput={clientData} datasetByTitle={dataset["by_title"]} dynamicValues={dynamicValues} updatePfClientData={updatePfClientData} updateGroupItems={updateGroupItems} hideEmptyTables={false} foldEmptyTables={foldEmptyTables} readOnly={disabled} />

      </CardContent >
      {
        event &&
        <PrecisefpDialog open={openDialog} onClose={closeSyncDialog} event={event} clientData={clientData} datasetByTitle={dataset["by_title"]} dynamicValues={dynamicValues} />
      }

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!openErrorSnack}
        autoHideDuration={6000}
        onClose={() => setOpenErrorSnack(undefined)}
      >
        <Alert variant='filled' severity='error'>
          {openErrorSnack}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!openSuccessSnack}
        autoHideDuration={5000}
        onClose={() => setOpenSuccessSnack(undefined)}
      >
        <Alert variant='filled' severity='success'>
          {openSuccessSnack}
        </Alert>
      </Snackbar>
    </Card >
  )
}

const sxRow: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  gap: 2
}

const icon: SxProps = {
  width: 20,
  height: 20,
  marginRight: '5px'
}
