import React, { useState, useEffect, useMemo, useRef } from 'react'

import {
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
} from 'material-react-table'
import { Pension, ClientData } from '../../client'
import { foldTable, hideTable, FinanceTableProps, getEditOptions } from './financesUtils'
import TableAccordion from './TableAccordion'
import { getNumberCell, getLabelCell } from './CustomCells'
import { useCustomTable } from './useCustomTable'

export default function PensionTable<
  T extends keyof ClientData,
  S extends keyof NonNullable<ClientData[T]>
>({ eventId, title, type, subType, dataInput, datasetGroup, dynamicValues, hideEmptyTable, foldEmptyTable, readOnly, onUpdate }: FinanceTableProps<T, S>) {

  const [expanded, setExpanded] = useState(true)
  const [dataList, setDataList] = useState<Pension[]>([])
  const [newData, setNewData] = useState<Pension>()
  const [dsElements, setDsElements] = useState<any>()

  useEffect(() => {
    if (dataInput) {
      setDataList(dataInput as Pension[])
    }
  }, [dataInput])

  useEffect(() => {
    if (datasetGroup && datasetGroup['elements']) {
      setDsElements(datasetGroup['elements'])
    }
  }, [datasetGroup])

  useEffect(() => {
    setExpanded(foldTable(foldEmptyTable, dataList))
  }, [foldEmptyTable, dataList])

  const handleCreate: MRT_TableOptions<Pension>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const updated = [...dataList]
    if (newData) {
      updated.push(newData)
      const error = await onUpdate(type, subType, updated)
      if (error) {
        return
      }
    }
    table.setCreatingRow(null) // exit creating mode
  }

  const handleEdit = async () => {
    const error = await onUpdate(type, subType, dataList)
    if (error) {
      if (dataInput)
        setDataList(dataInput as Pension[])
      return
    }
    table.setEditingRow(null)
  }

  function handleEditCancel() {
    if (dataInput)
      setDataList(dataInput as Pension[])
    return
  }

  function handleDelete(row: MRT_Row<Pension>) {
    const updated = [...dataList]
    if (row.index >= 0) {
      updated.splice(row.index, 1)
      onUpdate(type, subType, updated as NonNullable<ClientData[T]>[S])
    }
  }

  const columns = useMemo<MRT_ColumnDef<Pension>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Name': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const value = (event.target.value)
            setDataList(prevDataList =>
              prevDataList.map((item, index) =>
                index === rowIdx ? { ...item, ['Name']: value } : item
              )
            )
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Owner',
        header: 'Owner',
        editSelectOptions: () => getEditOptions(dsElements, 'Owner', dynamicValues),
        Cell: getLabelCell(() => getEditOptions(dsElements, 'Owner', dynamicValues)),
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Owner': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const value = (event.target.value)
            setDataList(prevDataList =>
              prevDataList.map((item, index) =>
                index === rowIdx ? { ...item, ['Owner']: value } : item
              )
            )
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Distribution Type',
        header: 'Distribution Type',
        editSelectOptions: getEditOptions(dsElements, 'Distribution Type', dynamicValues),
        Cell: getLabelCell(() => getEditOptions(dsElements, 'Distribution Type', dynamicValues)),
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Distribution Type': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const value = (event.target.value)
            setDataList(prevDataList =>
              prevDataList.map((item, index) =>
                index === rowIdx ? { ...item, ['Distribution Type']: value } : item
              )
            )
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Distribution Amount',
        header: 'Distribution Amount',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Distribution Amount': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.id)
            const value = Number(event.target.value)
            setDataList(prevDataList =>
              prevDataList.map((item, index) =>
                index === rowIdx ? { ...item, ['Distribution Amount']: value } : item
              )
            )
          },
        }),
        Cell: getNumberCell(),
        size: 100
      },
      {
        accessorKey: 'Distribution Year',
        header: 'Distribution Year',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Distribution Year': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.id)
            const value = Number(event.target.value)
            setDataList(prevDataList =>
              prevDataList.map((item, index) =>
                index === rowIdx ? { ...item, ['Distribution Year']: value } : item
              )
            )
          },
        }),
        size: 100
      },
    ],
    [dataList, newData, dsElements, dynamicValues],
  )

  const table = useCustomTable({
    columns,
    data: dataList,
    readOnly: readOnly,
    tableTitle: title,
    onDelete: handleDelete,
    onCreate: handleCreate,
    onEditRow: handleEdit,
    onEditRowCancel: handleEditCancel,
    // onCancel: handleCancel,
  })

  if (hideTable(hideEmptyTable, dataList)) {
    return (
      <></>
    )
  }

  return (
    <TableAccordion title={title} table={table} readOnly={readOnly} expanded={expanded} setExpanded={setExpanded} />
  )
}
