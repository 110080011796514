import React, { useState, useEffect, useMemo, useRef, useCallback, forwardRef, useImperativeHandle } from 'react'

import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
} from 'material-react-table'
import { PersonalDetails } from '../../client'
import { getNumberCell, getLabelCell } from './CustomCells'
import { foldTable, hideTable, FinanceTableWithMemberIdProps, getEditOptions, TableRef, getUniqueMemberId } from './financesUtils'
import { useCustomTable } from './useCustomTable'

const PersonalDetailsTable = forwardRef<TableRef, FinanceTableWithMemberIdProps<'Client Details', 'Personal Details'>>(
  ({ eventId, title, type, subType, dataInput, datasetGroup, dynamicValues, hideEmptyTable, foldEmptyTable, readOnly, memberId, onUpdate }, ref) => {

    const [dataList, setDataList] = useState<PersonalDetails[]>([])
    const [newData, setNewData] = useState<PersonalDetails>()
    const [dsElements, setDsElements] = useState<any>()
    const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({})

    useEffect(() => {
      if (dataInput) {
        setDataList(dataInput)
      }
    }, [dataInput])

    useEffect(() => {
      if (datasetGroup && datasetGroup['elements']) {
        setDsElements(datasetGroup['elements'])
      }
    }, [datasetGroup])

    useImperativeHandle(ref, () => ({
      createRowFunction: () => {
        // clear existing validation error
        setValidationErrors({})
        table.setCreatingRow(true)
      },
    }))

    const validateField = (key: string, value: any): string | undefined => {
      if (['First Name', 'Last Name'].includes(key) && (!value || value.length == 0)) {
        return `${key} is required`
      }
      return undefined
    }

    function validateData(data: any) {
      return {
        'First Name': validateField('First Name', data['First Name']),
        'Last Name': validateField('Last Name', data['Last Name']),
      }
    }

    const handleCreate: MRT_TableOptions<PersonalDetails>['onCreatingRowSave'] = async ({
      values,
      table,
    }) => {
      const newValidationErrors = validateData(values)
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors)
        return
      }

      if (newData) {
        const newPerson = { ...newData }
        const newMemberId = getUniqueMemberId(dynamicValues)
        newPerson.member_id = newMemberId
        setNewData(undefined)
        onUpdate(type, subType, [newPerson], newMemberId)
      }
      table.setCreatingRow(null) // exit creating mode
    }

    const handleEdit = async () => {
      const error = await onUpdate(type, subType, dataList, memberId)
      if (error) {
        if (dataInput)
          setDataList(dataInput)
        return
      }
      table.setEditingRow(null)
    }

    function handleEditCancel() {
      if (dataInput)
        setDataList(dataInput)
      return
    }

    const columns = useMemo<MRT_ColumnDef<PersonalDetails>[]>(
      () => [
        {
          accessorKey: 'First Name',
          header: 'First Name',
          muiEditTextFieldProps: ({ row }) => ({
            required: true,
            error: !!validationErrors?.['First Name'],
            helperText: validationErrors?.['First Name'],
            onFocus: () =>
              setValidationErrors({
                ...validationErrors,
                'First Name': undefined,
              }),
            onBlur: (event) => {
              const error = validateField("First Name", event.target.value)
              if (error) {
                setValidationErrors(prev => ({ ...prev, 'First Name': error }))
                return
              }

              if (row.index < 0) {
                setNewData((prevNew) => ({
                  ...prevNew,
                  'First Name': event.target.value,
                }))
                return
              }
              const rowIdx = Number(row.id)
              const value = event.target.value
              setDataList(prevDataList =>
                prevDataList.map((item, index) =>
                  index === rowIdx ? { ...item, ['First Name']: value } : item
                )
              )
            },
          }),
          size: 100
        },
        {
          accessorKey: 'Last Name',
          header: 'Last Name',
          muiEditTextFieldProps: ({ row, cell }) => ({
            required: true,
            error: !!validationErrors?.['Last Name'],
            helperText: validationErrors?.['Last Name'],
            onFocus: () =>
              setValidationErrors({
                ...validationErrors,
                'Last Name': undefined,
              }),
            onBlur: (event) => {
              const error = validateField("Last Name", event.target.value)
              if (error) {
                setValidationErrors(prev => ({ ...prev, 'Last Name': error }))
                return
              }
              if (row.index < 0) {
                setNewData((prevNew) => ({
                  ...prevNew,
                  'Last Name': event.target.value,
                }))
                return
              }
              const rowIdx = Number(row.id)
              const value = event.target.value
              setDataList(prevDataList =>
                prevDataList.map((item, index) =>
                  index === rowIdx ? { ...item, ['Last Name']: value } : item
                )
              )
            },
          }),
          size: 100
        },
        {
          accessorKey: 'Gender',
          header: 'Gender',
          editVariant: 'select',
          editSelectOptions: getEditOptions(dsElements, 'Gender', dynamicValues),
          Cell: getLabelCell(() => getEditOptions(dsElements, 'Gender', dynamicValues)),
          muiEditTextFieldProps: ({ row }) => ({
            select: true,
            onBlur: (event) => {
              if (row.index < 0) {
                setNewData((prevNew) => ({
                  ...prevNew,
                  'Gender': event.target.value,
                }))
                return
              }
              const rowIdx = Number(row.id)
              const value = event.target.value
              setDataList(prevDataList =>
                prevDataList.map((item, index) =>
                  index === rowIdx ? { ...item, ['Gender']: value } : item
                )
              )
            },
          }),
          size: 30
        },
        {
          accessorKey: 'Birthdate',
          header: 'Birthdate', // TODO: format check
          muiEditTextFieldProps: ({ row }) => ({
            onBlur: (event) => {
              if (row.index < 0) {
                setNewData((prevNew) => ({
                  ...prevNew,
                  'Birthdate': event.target.value,
                }))
                return
              }
              const rowIdx = Number(row.id)
              const value = event.target.value
              setDataList(prevDataList =>
                prevDataList.map((item, index) =>
                  index === rowIdx ? { ...item, ['Birthdate']: value } : item
                )
              )
            },
          }),
          size: 100
        },
        {
          accessorKey: 'Marital Status',
          header: 'Marital Status',
          editVariant: 'select',
          editSelectOptions: getEditOptions(dsElements, 'Marital Status', dynamicValues),
          Cell: getLabelCell(() => getEditOptions(dsElements, 'Marital Status', dynamicValues)),
          muiEditTextFieldProps: ({ row }) => ({
            select: true,
            onBlur: (event) => {
              if (row.index < 0) {
                setNewData((prevNew) => ({
                  ...prevNew,
                  'Marital Status': event.target.value,
                }))
                return
              }
              const rowIdx = Number(row.id)
              const value = event.target.value
              setDataList(prevDataList =>
                prevDataList.map((item, index) =>
                  index === rowIdx ? { ...item, ['Marital Status']: value } : item
                )
              )
            },
          }),
          size: 100
        },
        { // TODO: list
          accessorKey: 'Citizenship',
          header: 'Citizenship',
          muiEditTextFieldProps: ({ row }) => ({
            onBlur: (event) => {
              if (row.index < 0) {
                setNewData((prevNew) => ({
                  ...prevNew,
                  'Citizenship': event.target.value,
                }))
                return
              }
              const rowIdx = Number(row.id)
              const value = event.target.value
              setDataList(prevDataList =>
                prevDataList.map((item, index) =>
                  index === rowIdx ? { ...item, ['Citizenship']: value } : item
                )
              )
            },
          }),
          size: 100
        },
      ],
      [dataList, newData, dsElements, dynamicValues, validationErrors],
    )

    const table = useCustomTable({
      columns,
      data: dataList,
      tableTitle: title,
      readOnly: readOnly,
      disableDelete: true,
      disableActions: true,
      onCreate: handleCreate,
      onEditRow: handleEdit,
      onEditRowCancel: handleEditCancel,
    })

    return (
      <>
        <MaterialReactTable table={table} />
      </>

    )
  }
)

PersonalDetailsTable.displayName = 'PersonalDetailsTable'

export default PersonalDetailsTable